

  @keyframes spin {
    0% { content:"ipsum" }
    10% { content:"dolor" }
    20% { content:"sit" }
    30% { content:"amet" }
    40% { content:"consectetur" }
    50% { content: "adipisicing" }
    60% { content: "elit" }
    70% { content: "Hic" }
    80% { content: "atque"}
    90% { content: "fuga" }
  }
  
  @font-face {
    font-family: system;
    font-style: normal;
    
    src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
  }
  
  .serv-container {
    margin:0;
    padding:0;
    background:transparent;
    font-family: "system";
  }
  
  .serv-container {
    position: relative;
    max-width: 300px;
    text-align: center;
    height: 35px;
    line-height: 40px;
    margin: 0px auto;
    color: #3a4046;
  }
  
  .word {
    font-size:2em;
    font-weight:700;
    opacity:0;
    position: absolute;
    width: 100%;
    text-align: center;
    background-color: #566cec;
    background-image: linear-gradient(
90deg,#14CBA7,#566cec 25%,#14CBA7 75%,#ff7c51);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }
  
  .w1 {
    -webkit-animation: w1anim 12s infinite;
            animation: w1anim 12s infinite;
  }
  
  .w2 {
    -webkit-animation: w2anim 12s infinite;
            animation: w2anim 12s infinite;
  }
  
  .w3 {
    -webkit-animation: w3anim 12s infinite;
            animation: w3anim 12s infinite;
  }
  
  .w4 {
    -webkit-animation: w4anim 12s infinite;
            animation: w4anim 12s infinite;
  }
  
  .w5 {
    -webkit-animation: w5anim 12s infinite;
            animation: w5anim 12s infinite;
  }
  
  
  
  @-webkit-keyframes w1anim {
    0%{
      opacity: 0;
    }
    10%{
      opacity: 1;
    }
    20% {
      opacity:0;
    }
  }
  
  
  
  @keyframes w1anim {
    0%{
      opacity: 0;
    }
    10%{
      opacity: 1;
    }
    20% {
      opacity:0;
    }
  }
  
  
  @-webkit-keyframes w2anim {
    20%{
      opacity: 0;
    }
    30%{
      opacity: 1;
    }
    40% {
      opacity:0;
    }
  }
  
  
  @keyframes w2anim {
    20%{
      opacity: 0;
    }
    30%{
      opacity: 1;
    }
    40% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes w3anim {
    40%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    60% {
      opacity:0;
    }
  }
  
  @keyframes w3anim {
    40%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    60% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes w4anim {
    60%{
      opacity: 0;
    }
    70%{
      opacity: 1;
    }
    80% {
      opacity:0;
    }
  }
  
  @keyframes w4anim {
    60%{
      opacity: 0;
    }
    70%{
      opacity: 1;
    }
    80% {
      opacity:0;
    }
  }
  
  @-webkit-keyframes w5anim {
    80%{
      opacity: 0;
    }
    90%{
      opacity: 1;
    }
    100% {
      opacity:0;
    }
  }
  
  @keyframes w5anim {
    80%{
      opacity: 0;
    }
    90%{
      opacity: 1;
    }
    100% {
      opacity:0;
    }
  }