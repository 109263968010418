body{
  font-family: "Rubik",sans-serif;
}
body, html {
  background: #fff;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.earn-btn{
    color: #fff;
    text-decoration: none;
    display: inline-block;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: #14CBA7;
    font-family: "Open Sans", sans-serif;
    cursor: pointer;
    border: 1px solid transparent;

}
.earn-btn:hover
{
  color: #fff!important;
}

.jumbotron{
  margin-bottom: 0;
}

.navbar{
  border-bottom: .1rem solid #f0f0f0;
}

.nav-link{
  
  font-size: 0.9rem;
  margin-right: 30px;
  font-weight: 600;
    line-height: 1.7;
    color:#3a4046;;
}
.nav-link:hover {
  color: #6F8FAF;
}
.nav-link:active {
  color: #3a4046!important;
}

.nav-link:focus {
  color: #3a4046;
}
a:hover {
  color: #6F8FAF;
}

.hero-block{
  padding: 3rem;
  /* border-radius: 4rem;
  background-color: #fbfbfb; */
}

.main-hero{
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

/* .hero-image{
  max-width: 400px;
} */

.img-block{
  display: flex;
  justify-content: end;
  max-width: 400px;
}
.hero-content{
  justify-content: space-between;
}

.hero-col{
  border-radius: 2rem;
  background-color: #fbfbfb;
  margin: 10px;
}

.hero-row{
 
    margin: 0 auto;
    padding: 10px 0;
    min-height: 250px;
   
}
.stock-col a{
  text-decoration: none;
  color: rgb(0, 82, 73)!important;
}
.stock-col{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    box-shadow: none;
    text-align: center;
    padding: 40px 0px;
    color: rgb(0, 82, 73);
    background-color: rgb(163 255 196);
    /* background-color: rgb(200, 250, 205); */
}

.dividend-col a{
  text-decoration: none;
  color: rgb(122, 12, 46);
}
.dividend-col{

    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    box-shadow: none;
    text-align: center;
    padding: 40px 0px;
  
    background-color: rgb(255, 231, 217);
}

.buy-col a{
  text-decoration: none;
  color: rgb(122, 79, 1);
}

.buy-col{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    box-shadow: none;
    text-align: center;
    padding: 40px 0px;
    color: rgb(122, 79, 1);
    background-color: rgb(255, 247, 205);
}

.ipo-col a{
  text-decoration: none;
  color: rgb(4, 41, 122);
}

.ipo-col{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
  z-index: 0;
  box-shadow: none;
  text-align: center;
  padding: 40px 0px;
  color: rgb(4, 41, 122);
  background-color: rgb(208, 242, 255);
 
}

.dropdown-item{
  font-size: 0.9rem;
}

table{
  border-bottom-color:grey!important;
}

table > thead{
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    border-radius: 16px;
    position: relative;
    z-index: 0;
    box-shadow: none;
    text-align: center;
    padding: 40px 0px;
    color: #233242;
    background: #acc8e6;
    font-size: 14px;
    padding: 8px 18px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;

}
table > thead > tr > th{
  border-radius: 25px !important;
  /* border-bottom: 5px solid #52a595!important; */
  border-bottom: none;
}

table > tbody > tr > td{
  border-bottom-color:grey!important;
}

.learn-block{
  text-align: left;
}

.learn-block h5{
  color: #6082B6;
  font-weight: 600;
}

.app-section img{
   margin: 10px;
}
/* 
table.table-bordered{
  border:1px solid gray;
  margin-top:20px;
}
table.table-bordered > thead > tr > th{
  border:1px solid rgb(226, 226, 240);
}
table.table-bordered > tbody > tr > td{
  border:1px solid rgb(230, 230, 242);
} */

/* .updates{
  background-color: #f1f4f8;
} */
.updates-section{
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 5%;
}
.weekly-stocks{
  padding: 2em;
  background-color: #f1f4f8;
  flex: 70%;
  border-radius: 25px;
}
.quarterly-stocks{
  flex: 30%;
  padding: 2em;
}
.quarterly-stocks table > thead { 
  text-align: left;
  font-size: 16px;
  padding: 40px 0px;
  color: #233242;
  background: none;
}
.quarterly-stockstable > tbody > tr > td{
  font-size: 15px;
}

@media screen and (max-width: 575px) {
  .nav-link{
    margin-bottom: 0%;
  }
}

@media (max-width: 991px)
{
  .hero-block{
    padding: 0rem;
  }
  .img-block{
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 767px){
  .main-hero{
    flex-direction: column;
  }

  .updates-section{
    flex-direction: column;
  }
  .img-block {
    max-width: 800px;
  }

}

@media (min-width: 1200px) {  

  
.earn-btn{
  margin-right: -200px;
}

    
}
 
